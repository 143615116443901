import React, { Component } from 'react'

export default class Footer extends Component {
    render() {
        return (
            <footer>

                <div className="wrapper">
                    <div className="footer-left">
                        <i className="signature"></i>
                    </div>

                    <div className="footer-right">
                        <p>Contact for Enquiries:</p>

                        <div className="social-engagement">
                            <ul>
                                <li>
                                    <a href="mailto:fal90210@gmail.com" className="email-icon">Email me!</a>
                                </li>

                                <li>
                                    <a href="http://twitter.com/fal_pan" target="_blank" rel="noopener noreferrer" title="Twitter Link" className="twitter-icon">Tweet</a>
                                </li>

                                <li>
                                    <a href="http://www.behance.net/kushpandya" target="_blank" rel="noopener noreferrer" title="Behance Link" className="behance-icon">Check me out on Behance!</a>
                                </li>
                            </ul>
                        </div>

                        <p>For more samples and references: </p>
                    </div>
                </div>

            </footer>
        )
    }
}
