import React, { Component } from 'react'

export default class About extends Component {
    render() {
        return (
            <section className="colorlib-about" data-section="about">

                <div className="falgun timeline-entry animate-box" data-animate-effect="fadeInRight"></div>

                <div className="colorlib-narrow-content">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="row row-bottom-padded-sm animate-box" data-animate-effect="fadeInTop">
                                <div className="col-md-12">
                                    <div className="about-desc">
                                        <span className="heading-meta">About Me</span>
                                        <h2 className="colorlib-heading">Introduction</h2>

                                        <div className="about-wrapper">
                                            <p>
                                                Hi, My name is Falgun Pandya and I'm an Accessibility SME based in Toronto, Canada with
                                                over 16+ years of hands on experience designing &amp; developing on multiple platforms, design systems and frameworks.
                                            </p>
                                            <p>
                                                I started my journey as an Illustrator/Designer and along the years, worked my way to Senior Product Management with specialization in Accessibility and Compliance. I code &amp; train teams to code clean, symantic, well
                                                documented Section 508/AODA/ADA/EU 301-549/WCAG 2.X Level AA compliant code.</p>

                                            <p>I have worked on and have led project development teams for clients in diverse industries including technology, education, health &amp;
                                                wellness, transportation &amp; logistics, eCommerce/shopping carts, medical &amp; pharmaceutical,
                                                fashion, social media, state government, political campaign, hospitality &amp; tourism, advertising, television and banking &amp; financial services.
                                                I have designed and developed for the web, mobile, tablets, kiosks and very large digital splash screens.
                                                I have worked with bilingual &amp; multilingual web applications of varied complexities for global clients.
                                            </p>

                                            <p>
                                                Thank You for visiting and previewing my work, Have an awesome day!
                                            </p>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        )
    }
}
