import React, { Component } from 'react'

export default class Tryout extends Component {
    render() {
        return (
            <section className="colorlib-tryout" data-section="home">

            </section>
        )
    }
}
