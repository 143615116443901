import React, { Component } from 'react'

const Pan = "Pan";

export default class Introduction extends Component {
    render() {
        return (
            <header id="colorlib-superHero" className="js-fullheight" data-section="home">

                <div id="clouds">
                    <div className="cloud x1"></div>
                    <div className="cloud x2"></div>
                    <div className="cloud x3"></div>
                    <div className="cloud x4"></div>
                    <div className="cloud x5"></div>
                </div>

                <div className="windmill">
                    {/*<div className="cloud" divstyle="animation-play-state: running !important;"></div>*/}
                    <div className="mill-container">
                        <div className="body">
                            <div className="window">
                                <div className="window--door"></div>
                                <div className="window--door"></div>
                            </div>
                        </div>
                        <div className="base">
                            <div className="window">
                                <div className="window--door"></div>
                                <div className="window--door"></div>
                            </div>
                        </div>
                        <div className="top">
                            <div className="midsection"></div>
                            <div className="dome"></div>
                            <div className="wieken" divstyle="animation-play-state: running !important;">
                                <div className="wiek">
                                    <table>
                                        <tbody>
                                        <tr>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                        </tr>
                                        <tr>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                        </tr>
                                        </tbody>
                                    </table>
                                </div>
                                <div className="wiek">
                                    <table>
                                        <tbody>
                                        <tr>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                        </tr>
                                        <tr>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                        </tr>
                                        </tbody>
                                    </table>
                                </div>
                                <div className="wiek">
                                    <table>
                                        <tbody>
                                        <tr>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                        </tr>
                                        <tr>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                        </tr>
                                        </tbody>
                                    </table>
                                </div>
                                <div className="wiek">
                                    <table>
                                        <tbody>
                                        <tr>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                        </tr>
                                        <tr>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                        </tr>
                                        </tbody>
                                    </table>
                                </div>
                                <div className="wieken--circle"></div>
                            </div>
                        </div>
                        <div className="walkway">
                            <table>
                                <tbody>
                                <tr>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>

                </div>

                <div className="js-fullheight">

                    <div className="new-logo-container">

                        <h1>Fal<span>{Pan}</span></h1>

                        <div className="short-intro">
                            UI/UX Designer + Frontend Engineer + Accessibility SME
                        </div>

                        <div className="social-engagement">

                                <span>
                                    <a href="mailto:fal90210@gmail.com" className="email-icon-white">Email me!</a>
                                </span>

                                {/*
                                <span>
                                    <a href="http://twitter.com/fal_pan" target="_blank" rel="noopener noreferrer"
                                       className="twitter-icon-white">Tweet</a>
                                </span>

                                <span>
                                    <a href="http://www.behance.net/kushpandya" target="_blank"
                                       rel="noopener noreferrer" className="behance-icon-white">Check me out on
                                        Behance!</a>
                                </span>

                                <span>
                                    <a href="#home" className="dribble-icon-white">Check me out on Dribble!</a>
                                </span>
                                */}

                        </div>
                    </div>

                </div>

                <div className="scroll-wrapper">
                    <div className="mouse"></div>
                    <span>Scroll</span>
                </div>
            </header>
        )
    }
}
