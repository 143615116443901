import React, { Component } from 'react'

export default class Sidebar extends Component {
    render() {
        return (
            <section>
                <nav href="#navbar" className="js-colorlib-nav-toggle colorlib-nav-toggle" data-toggle="collapse"
                     data-target="#navbar" aria-expanded="false" aria-controls="navbar"><i /></nav>

                <aside id="colorlib-aside" className="clearHeader">

                    <div className="wrapper">
                        <div className="intro">
                            <a href="https://falpan.com" data-nav-section="home" title="FalPan logo">
                                <i className="logo"></i>
                            </a>
                        </div>

                        <nav id="colorlib-main-menu" role="navigation" className="navbar">
                            <div id="navbar" className="collapse">
                                <ul>
                                    <li className="active"><a href="#home" data-nav-section="home" title="home">Home</a></li>
                                    <li><a href="#about" data-nav-section="about">About</a></li>
                                    <li><a href="#timeline" data-nav-section="timeline">Works</a></li>
                                </ul>
                            </div>
                        </nav>
                    </div>
                </aside>
            </section>
        )
    }
}
