import React, { Component } from 'react'

export default class Collage extends Component {
    render() {
        return (
            <section className="colorlib-collage">
                <div className="collage">
                    <div className="collage-inner-wrapper">
                        <h1>New version coming very soon!</h1>

                        {/*
                        <div className="wrapper">
                            <div>
                                <i className="new-logo"></i>
                            </div>
                            <div>
                                <i className="pinu-logo"></i>
                            </div>
                            <div>
                                <i className="guelph-logo"></i>
                            </div>
                            <div>
                                <i className="pinu-logo"></i>
                            </div>
                            <div>
                                <i className="pinu-logo"></i>
                            </div>

                            <div>
                                <i className="new-logo"></i>
                            </div>

                            <div>
                                <i className="pinu-logo"></i>
                            </div>
                            <div>
                                <i className="guelph-logo"></i>
                            </div>
                            <div>
                                <i className="pinu-logo"></i>
                            </div>
                        </div>
                        */}

                    </div>
                </div>
            </section>
        )
    }
}
