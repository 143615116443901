import React, { Component } from 'react';
//import './App.css';
import Sidebar from './components/sidebar'
import Introduction from './components/introduction'
import Tryout from './components/tryout'
import About from './components/about'
import Timeline from './components/timeline'
import Collage from './components/collage'
import Footer from './components/footer'

class App extends Component {
  render() {
    return (
      <div id="colorlib-page">
        <div id="container-wrap">
         	<Sidebar></Sidebar>
				<div id="colorlib-main">
					<Introduction></Introduction>
                    <Tryout></Tryout>
					<About></About>
					<Timeline></Timeline>
                    <Collage></Collage>
                    <Footer></Footer>
          	</div>
      	</div>
      </div>
    );
  }
}

export default App;
