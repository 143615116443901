import React, { Component } from 'react';

//import screenshot11 from '../components/screenshot11.png';
//import screenshot12 from '../components/screenshot12.png';

// Screenshots
const screenshot0 = 'https://ik.imagekit.io/twl4vdftkm/FalPan/screenshot1_rssX5ECj_.png';
const screenshot = 'https://ik.imagekit.io/twl4vdftkm/FalPan/screenshot_xSwVtaKwf.png';
const screenshot1 = 'https://ik.imagekit.io/twl4vdftkm/FalPan/screenshot1_K7y-4Ds4h.png';
const screenshot2 = 'https://ik.imagekit.io/twl4vdftkm/FalPan/screenshot2_dPk5Vf8oDu.png';
const screenshot3 = 'https://ik.imagekit.io/twl4vdftkm/FalPan/screenshot3_Sc6sLDvpiw.png';
const screenshot4 = 'https://ik.imagekit.io/twl4vdftkm/FalPan/screenshot4_Hw7YNOqAl.png';
const screenshot5 = 'https://ik.imagekit.io/twl4vdftkm/FalPan/screenshot5_xLhSouk708.png';
const screenshot6 = 'https://ik.imagekit.io/twl4vdftkm/FalPan/screenshot6_MyANSXdPW-.png';
const screenshot7 = 'https://ik.imagekit.io/twl4vdftkm/FalPan/screenshot7_Kathg3qHwh.png';
const screenshot8 = 'https://ik.imagekit.io/twl4vdftkm/FalPan/screenshot8_ZOzSgglVeP.png';
const screenshot9 = 'https://ik.imagekit.io/twl4vdftkm/FalPan/screenshot9_BmVLvxdZzY.png';
const screenshot10 = 'https://ik.imagekit.io/twl4vdftkm/FalPan/screenshot10_89Sjt0-62.png';

const screenshot13 = 'https://ik.imagekit.io/twl4vdftkm/FalPan/screenshot13_za-3fncx-1.png';
const screenshot14 = 'https://ik.imagekit.io/twl4vdftkm/FalPan/screenshot14_RmaGvBWKK.svg';
const screenshot15 = 'https://ik.imagekit.io/twl4vdftkm/FalPan/screenshot15_N6rFM89wW.png';

// Mobile Images
const edusityMobile = 'https://ik.imagekit.io/twl4vdftkm/FalPan/edusity-mobile_GRJk0XDb4.png';
const thaliwallaMobile = 'https://ik.imagekit.io/twl4vdftkm/FalPan/thaliwalla-mobile_-IUMLAmd1k.png';
const webmyneMobile = 'https://ik.imagekit.io/twl4vdftkm/FalPan/webmyne-mobile_w3Ovx65u7.png';
const hububMobile = 'https://ik.imagekit.io/twl4vdftkm/FalPan/hubub-mobile_SSMUKXHgB.png';
const gilletteMobile = 'https://ik.imagekit.io/twl4vdftkm/FalPan/gillette-mobile_QNcg_bHAx.png';
const jpmorganMobile = 'https://ik.imagekit.io/twl4vdftkm/FalPan/jpmorgan-mobile_zItFP2vZf.png';
const hpMobile = 'https://ik.imagekit.io/twl4vdftkm/FalPan/hp-mobile_o-x78-ZS5.png';
const crMobile = 'https://ik.imagekit.io/twl4vdftkm/FalPan/cr-mobile_iC193eRlQ.png';
const med2xMobile = 'https://ik.imagekit.io/twl4vdftkm/FalPan/med2x-mobile_rVLS3e9Tz.png';
const acMobile = 'https://ik.imagekit.io/twl4vdftkm/FalPan/ac-mobile_EDkgkOx5u.png';
const guelphMobile = 'https://ik.imagekit.io/twl4vdftkm/FalPan/guelph-mobile_lZ38KktqG7.svg';
const pinuMobile = 'https://ik.imagekit.io/twl4vdftkm/FalPan/pinu-mobile_mc9ZgdDWK.png';




console.log("Taking a look under the hood EH!");
// console.log('%c Taking a look under the hood EH! ', 'background: #222; color: #f60606;');

export default class Timeline extends Component {
    render() {
        return (
            <section className="colorlib-experience" data-section="timeline">

                <div className="">

                    {/* Edusity Modal */}
                    <div className="modal fade-scale" id="myModal" tabIndex="-1" role="dialog" aria-labelledby="myModal">
                        <div className="modal-dialog" role="document">
                            <div className="modal-content">

                                <div className="modal-header">
                                    <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                        <div className="close-container close-btn" aria-hidden="true">
                                            <div className="leftright"></div>
                                            <div className="rightleft"></div>
                                        </div>
                                    </button>
                                </div>

                                <div className="modal-body">

                                    <div className="project-image edusity">
                                        <div className="logo-wrapper">
                                            <img src="https://ik.imagekit.io/twl4vdftkm/FalPan/Icons/edusity-logo-white_VB2HipC74is.svg" alt="Edusity Logo" />
                                            <div className="visit">
                                                <div><a data-dismiss="modal" href="#home">Back to Projects</a></div>
                                                <div><a title="Visit external website" target="_blank" href="https://www.edusity.com/" rel="noopener noreferrer">Visit Website</a></div>

                                                <div className="arrow-down-container">
                                                    <i className="arrow-down-large" />
                                                </div>
                                            </div>
                                        </div>
                                    </div>


                                    <div className="modal-description">
                                        <h2>Edusity #letslearntogether</h2>

                                        <p>Edusity founded in 2018, provides online training and education via webinar/video conferencing. The platform connects students and trainees from around the world with instructors and trainers.</p>

                                        <p>I joined Edusity in Dec 2020 to transform, re-brand and re-engineer its core platform capabilities and how it delivers live and pre-recorded course material. The new system will be fully accessible and runs on cloud infrastructure and presents immersive course material via secure APIs. The new system would be unique and first of its kind in Canada.</p>

                                        <div className="logo-wrapper edusity">
                                            <img src="https://ik.imagekit.io/twl4vdftkm/FalPan/Icons/edusity-logo-original_4A5TrusR3.svg" alt="Edusity Logo" />
                                        </div>

                                        <h2>100% Multilingual</h2>
                                        <p>The system on release will be 100% multilingual capable with the ability to add multiple languages on the fly. Future releases will include support for RTL languages as well!</p>
                                        
                                        <h2>Integrated Dashboard</h2>
                                        
                                        <div className="overlay-image-container">
                                            <img alt="Dashboard Screenshot" src="https://ik.imagekit.io/twl4vdftkm/FalPan/dashboard_screen_vjFMyMHxH5q.png" />
                                        </div>

                                        <p>The immersive and enhanced integrated dashboard provides unique customized White Label features and 1-click management making everything a breeze. New features include drag-n-drop course builder, tracking student progress, Quiz &amp; examination module (with in-built multiple choice questions, polls, fill-in-the-blanks), Stat monitoring and many more...</p>

                                        <h2>Custom Video Player Skin</h2>

                                        <div className="overlay-image-container">
                                            <img alt="Custom video player skin" src="https://ik.imagekit.io/twl4vdftkm/FalPan/Video_screen_1_29QvMeyVU.webp" />
                                        </div>

                                        <p>Implemented custom skin for video player the with capability to add CC(Closed Captioning), Subtitles and DA(Descriptive Audio) &amp; multilingual sub-titles, to meet WCAG 2.1 guidelines.</p>
                                        
                                        <h2>Colors</h2>

                                        <div className="color-box-container edusity">
                                            <div className="color-box">#AF2DF8</div>
                                            <div className="color-box">#930AF1</div>
                                            <div className="color-box">#5B0596</div>
                                            <div className="color-box">#F601C2</div>
                                        </div>

                                        <h2>Typography</h2>

                                        <div className="typography-container">
                                            <div>
                                                <div className="font-title">Primary Font Family</div>
                                                <div className="font-subtitle">'Nunito Sans', sans-serif</div>
                                            </div>
                                        </div>

                                        <p>The website is curently scheduled for release March 2022 with the re-engineered and re-designed looks and will meet regulatory compliances in Canada, the US &amp; Europe for individual &amp; Enterprise clients.</p>

                                        <button type="button" className="btn btn-default" data-dismiss="modal">Close</button>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                    
                    {/* Thaliwalla Modal */}
                    <div className="modal fade-scale" id="myModal1" tabIndex="-1" role="dialog" aria-labelledby="myModal1">
                        <div className="modal-dialog" role="document">
                            <div className="modal-content">

                                <div className="modal-header">
                                    <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                        <div className="close-container close-btn" aria-hidden="true">
                                            <div className="leftright"></div>
                                            <div className="rightleft"></div>
                                        </div>
                                    </button>
                                </div>

                                <div className="modal-body">

                                    <div className="project-image thaliwalla">
                                        <div className="logo-wrapper">
                                            <img src="https://ik.imagekit.io/twl4vdftkm/FalPan/Icons/thaliwalla-logo-white_QAiB-bVsY.svg" alt="Thaliwalla Logo" />
                                            <div className="visit">
                                                <div><a data-dismiss="modal" href="#home">Back to Projects</a></div>
                                                <div><a title="Visit external website" target="_blank" href="http://thaliwalla.com" rel="noopener noreferrer">Website Offline</a></div>

                                                <div className="arrow-down-container">
                                                    <i className="arrow-down-large" />
                                                </div>
                                            </div>
                                        </div>
                                    </div>


                                    {/*
                                    <div className="video-container">
                                        <video autoPlay={true} loop playsInline muted>
                                            <source src="../images/video.mp4" type="video/mp4" />
                                        </video>

                                        <div className="logo-wrapper">
                                            <img src="../images/thaliwalla-logo-white.svg" alt="Thaliwalla Logo" />
                                            <div className="visit">
                                                <span><a title="close overlay" data-dismiss="modal" href="#home">Back to Projects</a></span>
                                                <span><a title="Visit external website" target="_blank" href="http://thaliwalla.com" rel="noopener noreferrer">Visit Thaliwalla</a></span>
                                            </div>
                                        </div>
                                    </div>
                                     */}

                                    <div className="modal-description">
                                        <h2>The Story</h2>

                                        <p>This website was designed and created for a very lovely restaurateur owner couple Mr. & Mrs. (Mithun & Binki) Patel from London, Ontario. They wanted to re-design and re-brand their website with a modern look and feel but they also wanted to incorporate intricate elements of color and design from their cultural background.</p>

                                        <p>We stared with the logo -- something they did'nt have. Their old website was not meeting the Patel's requirements. Since the brand creation was the first step, out of 4 options, the Patel's decided to go with the current logo!</p>

                                        <div className="logo-wrapper">
                                            <img src="https://ik.imagekit.io/twl4vdftkm/FalPan/Icons/thaliwalla-logo_-wvGSqzMQ.svg" alt="Thaliwalla Logo" />
                                        </div>

                                        <div className="typography-container">
                                            <div>
                                                <div className="font-title">Logo Font Family</div>
                                                <div className="font-subtitle">'Sarina', cursive</div>
                                            </div>
                                        </div>

                                        <h2>Decorative Accents</h2>
                                        <div className="overlay-image-container">
                                            <img alt="Cultural Accents" src="https://ik.imagekit.io/twl4vdftkm/FalPan/garba_p-JmIiIdk.png" />
                                        </div>

                                        <p>A mixture of dark / light shades was what the owners preferred. They were looking for bright colors and fun theme with minimalistic yet elegant subtle animations.</p>

                                        <h2>Colors</h2>

                                        <div className="color-box-container thaliwalla">
                                            <div className="color-box">#ce3232</div>
                                            <div className="color-box">#921819</div>
                                            <div className="color-box">#333333</div>

                                            <div className="color-box">#d9d972</div>
                                            <div className="color-box">#698018</div>
                                            <div className="color-box">#006400</div>
                                        </div>

                                        <h2>Typography</h2>

                                        <div className="typography-container">
                                            <div>
                                                <div className="font-title">Primary Font Family</div>
                                                <div className="font-subtitle">'Montserrat', sans-serif</div>
                                            </div>

                                            <div>
                                                <div className="font-title">Fallback Font Family</div>
                                                <div className="font-subtitle">'Helvetica Neue', 'Arial', sans-serif</div>
                                            </div>
                                        </div>

                                        <p>The website went online in July-August 2020 and the Patels are happy with the new layout and design and have reported great reviews from customers and friends alike. I will also be designing their back large screen TVs and their flyers for local distribution. Wishing them the very best for their future.</p>

                                        <button type="button" className="btn btn-default" data-dismiss="modal">Close</button>
                                    </div>
                                </div>

                                {/*
                                <div className="modal-footer">
                                    <button type="button" className="btn btn-default" data-dismiss="modal">Close</button>
                                    <button type="button" className="btn btn-primary">Save changes</button>
                                </div>
                                 */}

                            </div>
                        </div>
                    </div>

                    {/* Webmyne Modal */}
                    <div className="modal fade-scale" id="myModal2" tabIndex="-1" role="dialog" aria-labelledby="myModal2">
                        <div className="modal-dialog" role="document">
                            <div className="modal-content">

                                <div className="modal-header">
                                    <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                        <div className="close-container close-btn" aria-hidden="true">
                                            <div className="leftright"></div>
                                            <div className="rightleft"></div>
                                        </div>
                                    </button>
                                </div>

                                <div className="modal-body">

                                     <div className="project-image webmyne">
                                        <div className="logo-wrapper">

                                            <img src="https://ik.imagekit.io/twl4vdftkm/FalPan/Icons/webmyne-logo-white_IT9nTkySF.svg" alt="Webmyne Logo" />

                                            <div className="visit">
                                                <div><a title="Back to Works" data-dismiss="modal" href="#home">Back to Projects</a></div>
                                                <div><a title="Visit external website" target="_blank" href="http://webmyne.com" rel="noopener noreferrer">Visit Website</a></div>

                                                <div className="arrow-down-container">
                                                    <i className="arrow-down-large" />
                                                </div>
                                            </div>
                                        </div>
                                     </div>

                                    <div className="modal-description">
                                        <h2>The Requirement</h2>

                                        <p>Serving Software service providers in the US, Canada and the Phillipines - this SaaS company offers turnkey software solutions and customized software solutions to your existing and brand new product launches.</p>

                                        <p>The website has been and continue to be modified since its previous version release in 2018, the original logo created in 2009. The logo continues to be the brand being used in Webmyne corporate offices across the world to this day.</p>

                                        <div className="logo-wrapper">
                                            <img src="https://ik.imagekit.io/twl4vdftkm/FalPan/Icons/webmyne-logo-gray_nNQZkc0cB.svg" alt="Webmyne Logo" />
                                        </div>

                                        <div className="typography-container">
                                            <div>
                                                <div className="font-title">Logo Font Family</div>
                                                <div className="font-subtitle">'Neuropolitical', serif</div>
                                            </div>
                                        </div>

                                        <p>Out of about half a dozen options - the current one was chosen to represent the global brand. A minimalistic representation that shows the futuristic technologies Webmyne uses for development.</p>

                                        <p>The plan was to create something in a neutral palette with a little splash of color. This theme has been modified quite a few times since its inception and several updated versions.</p>

                                        <h2>Typography</h2>

                                        <div className="typography-container">
                                            <div>
                                                <div className="font-title">Primary Font Family</div>
                                                <div className="font-subtitle">'Helvetica Neue', 'Arial', sans-serif</div>
                                            </div>

                                            <div>
                                                <div className="font-title">Fallback Font Family</div>
                                                <div className="font-subtitle">'Ubuntu', sans-serif</div>
                                            </div>
                                        </div>

                                        <p>Besides working on the Webmyne website, I have worked for several high profile clients for the company like Virginia Tourism (Virginia State Government), Adaroo, Commodity Rentals, bERP and many more. I designed and developed technology solutions for various industries and businesses with the help of latest and the most cutting edge tools for modern development.</p>

                                        <button type="button" className="btn btn-default" data-dismiss="modal">Close</button>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>

                    {/* Hubub Modal */}
                    <div className="modal fade-scale" id="myModal3" tabIndex="-1" role="dialog" aria-labelledby="myModal3">
                        <div className="modal-dialog" role="document">
                            <div className="modal-content">

                                <div className="modal-header">
                                    <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                        <div className="close-container close-btn" aria-hidden="true">
                                            <div className="leftright"></div>
                                            <div className="rightleft"></div>
                                        </div>
                                    </button>
                                </div>

                                <div className="modal-body">

                                    <div className="project-image hubub">
                                        <div className="logo-wrapper">

                                            <img src="https://ik.imagekit.io/twl4vdftkm/FalPan/Icons/hubub-logo-white_6JH9w5yfV.svg" alt="Hubub Logo" />

                                            <div className="visit">
                                                <div><a title="Back to Works" data-dismiss="modal" href="#home">Back to Projects</a></div>
                                                <div><a title="Visit external website" target="_blank" href="http://webmyne.com" rel="noopener noreferrer">Visit Website</a></div>

                                                <div className="arrow-down-container">
                                                    <i className="arrow-down-large" />
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="modal-description">
                                        <h2>The Requirement</h2>

                                        <p>I started with Hubub Inc. startup since its inception in 2011. The initial system was designed and built with Flash / Actionscript but as the project scope changed, so did the requirement. The system had to be overhauled. The complete architecture was redesigned with LAMP and my role was to build the templating engine and implementing the style guide from ground up, later upgraded the style implementation to compass/sass/scss.</p>

                                        <p>As time went by - it was a pain to maintain the system with so many icon libraries and there was a need to develop a mixin where the icons 1X & 2X formats would have to be generated w/gulp. Successfully implemented the compass mixin for easy icon implementation. Later in 2015-16 successfully implemented SVG icons integration which further reduced the use of retina and non-retina icons, way before it gained popularity.</p>

                                        <p>Implemented the CSS3 animation libraries to replace some jQuery animations and also developed & implemented lean UI/UX. Also Integrated/Consumed API services, AWS for UI development.</p>

                                        <button type="button" className="btn btn-default" data-dismiss="modal">Close</button>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>

                    {/* Gillette Modal */}
                    <div className="modal fade-scale" id="myModal4" tabIndex="-1" role="dialog" aria-labelledby="myModal4">
                        <div className="modal-dialog" role="document">
                            <div className="modal-content">

                                <div className="modal-header">
                                    <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                        <div className="close-container close-btn" aria-hidden="true">
                                            <div className="leftright"></div>
                                            <div className="rightleft"></div>
                                        </div>
                                    </button>
                                </div>

                                <div className="modal-body">

                                    <div className="project-image gillette">
                                        <div className="logo-wrapper">

                                            <img src="https://ik.imagekit.io/twl4vdftkm/FalPan/Icons/gillette-logo-white_kmA6q-hxw.svg" alt="Gillette Logo" />

                                            <div className="visit">
                                                <div><a title="Back to Works" data-dismiss="modal" href="#home">Back to Projects</a></div>
                                                <div><a title="Visit external website" target="_blank" href="https://www.gillettewelcomeback.com" rel="noopener noreferrer">Visit Website</a></div>

                                                <div className="arrow-down-container">
                                                    <i className="arrow-down-large" />
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="modal-description">
                                        <h2>Promotional Offer</h2>

                                        <p>Assigned as a lead developer to accomplish project in a record time. This was a promotional offer only for US residents.</p>

                                        <p>The goal was to register the user and ability to add photos, videos, articles, ads and poll segment with real time data analysis. In return, Gillette gave away free top of the line razors. The project was accomplished with a team of 6 devs and 2 designers.</p>

                                        <p>Integrated API services to the UI development efforts, implementing custom SVG icons, created robust UI to handle large amounts of data.</p>

                                        <button type="button" className="btn btn-default" data-dismiss="modal">Close</button>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>

                    {/* JP Morgan Modal */}
                    <div className="modal fade-scale" id="myModal5" tabIndex="-1" role="dialog" aria-labelledby="myModal5">
                        <div className="modal-dialog" role="document">
                            <div className="modal-content">

                                <div className="modal-header">
                                    <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                        <div className="close-container close-btn" aria-hidden="true">
                                            <div className="leftright"></div>
                                            <div className="rightleft"></div>
                                        </div>
                                    </button>
                                </div>

                                <div className="modal-body">

                                    <div className="project-image jpm">
                                        <div className="logo-wrapper">

                                            <img src="https://ik.imagekit.io/twl4vdftkm/FalPan/Icons/jpm-logo-white_1SRZDv_HQ.svg" alt="JP Morgan Logo" />

                                            <div className="visit">
                                                <div><a title="Back to Works" data-dismiss="modal" href="#home">Back to Projects</a></div>
                                                <div><a title="Visit external website" target="_blank" href="http://am.jpmorgan.com/ca/en/asset-management/institutional/" rel="noopener noreferrer">Visit Website</a></div>

                                                <div className="arrow-down-container">
                                                    <i className="arrow-down-large" />
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="modal-description">
                                        <h2>Project Details</h2>

                                        <p>I was assigned to get this project done - the flat design file views for web, tablet and mobile experience were being provided. I had to make the design functional focusing on UX. This was initially estimated as an SPA.</p>

                                        <p>I used React JS/JSX components with animation libraries to accomplish the desired visual UX. This included overlay animation and animated slider.</p>

                                        <p>I used custom animated SVG icons for navigation with SASS mixins to get that WOW effect!</p>

                                        <button type="button" className="btn btn-default" data-dismiss="modal">Close</button>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>

                    {/* Olay App Modal */}
                    <div className="modal fade-scale" id="myModal6" tabIndex="-1" role="dialog" aria-labelledby="myModal6">
                        <div className="modal-dialog" role="document">
                            <div className="modal-content">

                                <div className="modal-header">
                                    <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                        <div className="close-container close-btn" aria-hidden="true">
                                            <div className="leftright"></div>
                                            <div className="rightleft"></div>
                                        </div>
                                    </button>
                                </div>

                                <div className="modal-body">

                                    <div className="project-image olay">
                                        <div className="logo-wrapper">

                                            <img src="https://ik.imagekit.io/twl4vdftkm/FalPan/Icons/olay-logo-white_KGXksQrPz.svg" alt="Olay Logo" />

                                            <div className="visit">
                                                <div><a title="Back to Works" data-dismiss="modal" href="#home">Back to Projects</a></div>

                                                <div className="arrow-down-container">
                                                    <i className="arrow-down-large" />
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="modal-description">
                                        <h2>Project Details</h2>

                                        <p>This set of email campaign project was done for promotional 27 email blasts for subscribed customers in the United States. The email provided discount sale codes for online store, related article links & promotional banners.</p>

                                        <p>Overhauled the then existing email code and achieved 35% code reduction, custom font implementation and deployment.</p>

                                        <p>Achieved approximately 23% increase in traffic bounce from email over (monthly) reports</p>

                                        <button type="button" className="btn btn-default" data-dismiss="modal">Close</button>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>

                    {/* HP App Modal */}
                    <div className="modal fade-scale" id="myModal7" tabIndex="-1" role="dialog" aria-labelledby="myModal7">
                        <div className="modal-dialog" role="document">
                            <div className="modal-content">

                                <div className="modal-header">
                                    <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                        <div className="close-container close-btn" aria-hidden="true">
                                            <div className="leftright"></div>
                                            <div className="rightleft"></div>
                                        </div>
                                    </button>
                                </div>

                                <div className="modal-body">

                                    <div className="project-image hp">
                                        <div className="logo-wrapper short">

                                            <img src="https://ik.imagekit.io/twl4vdftkm/FalPan/Icons/hp-logo-blue_zeEbCu0tm.svg" alt="HP Logo" />

                                            <div className="visit">
                                                <div><a title="Back to Works" data-dismiss="modal" href="#home">Back to Projects</a></div>
                                                <div><a title="Visit external website" target="_blank" href="https://support.hp.com/us-en/drivers/printers" rel="noopener noreferrer">Visit Website</a></div>

                                                <div className="arrow-down-container">
                                                    <i className="arrow-down-large" />
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="modal-description">
                                        <h2>Project Details</h2>

                                        <p>My contribution to the project was approximately 3 weeks, icon implementation, bug fixes and adaptive/responsive views.</p>

                                        <button type="button" className="btn btn-default" data-dismiss="modal">Close</button>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>

                    {/* Commodity Rentals Modal */}
                    <div className="modal fade-scale" id="myModal8" tabIndex="-1" role="dialog" aria-labelledby="myModal8">
                        <div className="modal-dialog" role="document">
                            <div className="modal-content">

                                <div className="modal-header">
                                    <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                        <div className="close-container close-btn" aria-hidden="true">
                                            <div className="leftright"></div>
                                            <div className="rightleft"></div>
                                        </div>
                                    </button>
                                </div>

                                <div className="modal-body">

                                    <div className="project-image cr">
                                        <div className="logo-wrapper">

                                            <img src="https://ik.imagekit.io/twl4vdftkm/FalPan/Icons/cr-logo-white_A7bEvtmf6.svg" alt="Commodity Rentals Logo" />

                                            <div className="visit">
                                                <div><a title="Back to Works" data-dismiss="modal" href="#home">Back to Projects</a></div>
                                                <div><a title="Visit external website" target="_blank" href="http://am.jpmorgan.com/ca/en/asset-management/institutional/" rel="noopener noreferrer">Visit Website</a></div>

                                                <div className="arrow-down-container">
                                                    <i className="arrow-down-large" />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="modal-description">
                                        <h2>Project Details</h2>

                                        <p>Commodity Rentals develops unique and productive rental software for different rental software solution for industries.
                                            With offices in Canada and India, they provide their global customers with economical software solutions.
                                        </p>

                                        <p>Some of the software solutions provided and I've worked on are vacation rentals, DVD/Video/Movie Rentals (used to - discontinued), video games (used to - discontinued), Real estate/Property, Books/eBooks rental software.</p>

                                        <p>Created custom skins for Wordpress themes and implemted various projects within the software.</p>

                                        <button type="button" className="btn btn-default" data-dismiss="modal">Close</button>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>

                    {/* Alliance Chiropractor Modal */}
                    <div className="modal fade-scale" id="myModal9" tabIndex="-1" role="dialog" aria-labelledby="myModal9">
                        <div className="modal-dialog" role="document">
                            <div className="modal-content">

                                <div className="modal-header">
                                    <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                        <div className="close-container close-btn" aria-hidden="true">
                                            <div className="leftright"></div>
                                            <div className="rightleft"></div>
                                        </div>
                                    </button>
                                </div>

                                <div className="modal-body">

                                    <div className="project-image alliance">
                                        <div className="logo-wrapper">

                                            <img src="https://ik.imagekit.io/twl4vdftkm/FalPan/Icons/alliance-logo-white_PnVFly0fo.svg" alt="Alliance Chiropractic Logo" />

                                            <div className="visit">
                                                <div><a title="Back to Works" data-dismiss="modal" href="#home">Back to Projects</a></div>
                                                <div><a title="Visit external website" target="_blank" href="http://am.jpmorgan.com/ca/en/asset-management/institutional/" rel="noopener noreferrer">Visit Website</a></div>

                                                <div className="arrow-down-container">
                                                    <i className="arrow-down-large" />
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="modal-description">
                                        <h2>Project Details</h2>

                                        <p>Alliance Chiropractor</p>

                                        <button type="button" className="btn btn-default" data-dismiss="modal">Close</button>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>

                    {/* Med2X Modal */}
                    <div className="modal fade-scale" id="myModal10" tabIndex="-1" role="dialog" aria-labelledby="myModal10">
                        <div className="modal-dialog" role="document">
                            <div className="modal-content">

                                <div className="modal-header">
                                    <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                        <div className="close-container close-btn" aria-hidden="true">
                                            <div className="leftright"></div>
                                            <div className="rightleft"></div>
                                        </div>
                                    </button>
                                </div>

                                <div className="modal-body">

                                    <div className="project-image med2x">
                                        <div className="logo-wrapper">

                                            <img src="https://ik.imagekit.io/twl4vdftkm/FalPan/Icons/med2x-logo-white_1QJebOQaZ.svg" alt="Med2x Logo" />

                                            <div className="visit">
                                                <div><a title="Back to Works" data-dismiss="modal" href="#home">Back to Projects</a></div>
                                                <div><a title="Visit external website" target="_blank" href="http://am.jpmorgan.com/ca/en/asset-management/institutional/" rel="noopener noreferrer">Visit Website</a></div>

                                                <div className="arrow-down-container">
                                                    <i className="arrow-down-large" />
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="modal-description">
                                        <h2>Project Details</h2>

                                        <p>Med2x is a customization ready Hospital Management Software System. The system has integrated
                                        backend and API which delivers better and faster results.</p>

                                        <p>Med2X is the premier Health Management Software Products that leads a great
                                            market reputation for designing and developing result oriented solutions for its global clients.
                                            Specialist services are available to get a highly customized integrated system for managing
                                            hospital or healthcare center for better management and increases profitability.</p>

                                        <p>The enhanced integration offers modules like eClinic Software, eLaboratory Management Software and Out Patient Department Management Software.</p>

                                        <button type="button" className="btn btn-default" data-dismiss="modal">Close</button>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>

                    {/* Magic Survey Tool Modal */}
                    <div className="modal fade-scale" id="myModal11" tabIndex="-1" role="dialog" aria-labelledby="myModal11">
                        <div className="modal-dialog" role="document">
                            <div className="modal-content">

                                <div className="modal-header">
                                    <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                        <div className="close-container close-btn" aria-hidden="true">
                                            <div className="leftright"></div>
                                            <div className="rightleft"></div>
                                        </div>
                                    </button>
                                </div>

                                <div className="modal-body">

                                    <div className="project-image mst">
                                        <div className="logo-wrapper">

                                            <img src="https://ik.imagekit.io/twl4vdftkm/FalPan/Icons/mst-logo-white_-ZfDWr9M-.svg" alt="MST Logo" />

                                            <div className="visit">
                                                <div><a title="Back to Works" data-dismiss="modal" href="#home">Back to Projects</a></div>
                                                <div><a title="Visit external website" target="_blank" href="http://am.jpmorgan.com/ca/en/asset-management/institutional/" rel="noopener noreferrer">Visit Website</a></div>

                                                <div className="arrow-down-container">
                                                    <i className="arrow-down-large" />
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="modal-description">
                                        <h2>Project Details</h2>

                                        <p>Med2x is a customization ready Hospital Management Software System. The system has integrated
                                            backend and API which delivers better and faster results.</p>

                                        <p>Med2X is the premier Health Management Software Products that leads a great
                                            market reputation for designing and developing result oriented solutions for its global clients.
                                            Specialist services are available to get a highly customized integrated system for managing
                                            hospital or healthcare center for better management and increases profitability.</p>

                                        <button type="button" className="btn btn-default" data-dismiss="modal">Close</button>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>

                    {/* Guelph Realty Modal */}
                    <div className="modal fade-scale" id="myModal12" tabIndex="-1" role="dialog" aria-labelledby="myModal12">
                        <div className="modal-dialog" role="document">
                            <div className="modal-content">

                                <div className="modal-header">
                                    <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                        <div className="close-container close-btn" aria-hidden="true">
                                            <div className="leftright"></div>
                                            <div className="rightleft"></div>
                                        </div>
                                    </button>
                                </div>

                                <div className="modal-body">

                                    <div className="project-image guelph">
                                        <div className="logo-wrapper">

                                            <img src="https://ik.imagekit.io/twl4vdftkm/FalPan/Icons/guelph-logo-white_75p3pPR08.svg" alt="Guelph City Realty Logo" />

                                            <div className="visit">
                                                <div><a title="Back to Works" data-dismiss="modal" href="#home">Back to Projects</a></div>
                                                <div><a title="Visit external website" target="_blank" href="http://am.jpmorgan.com/ca/en/asset-management/institutional/" rel="noopener noreferrer">Visit Website</a></div>
                                            </div>

                                            <div className="arrow-down-container">
                                                <i className="arrow-down-large" />
                                            </div>
                                        </div>
                                    </div>

                                    <div className="modal-description">
                                        <h2>Project Details</h2>

                                        <div className="details">
                                            <div className="details-item">
                                                <h5>Project Status</h5>
                                                <p>Ongoing</p>
                                            </div>

                                            <div className="details-item">
                                                <h5>Estimated Completion</h5>
                                                <p>Nov-Dec 2020</p>
                                            </div>

                                            <div className="details-item">
                                                <h5>Team Total</h5>
                                                <p>5</p>
                                            </div>
                                        </div>

                                        <p>Guelph City Realty Inc., is a realtor listing website to list properties, features, interactive 3D walk-throughs and video.</p>

                                        <p>This project is stated to be completed by the end of the year 2020.</p>

                                        <button type="button" className="btn btn-default" data-dismiss="modal">Close</button>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>

                    {/* Pinu Modal */}
                    <div className="modal fade-scale" id="myModal13" tabIndex="-1" role="dialog" aria-labelledby="myModal13">
                        <div className="modal-dialog" role="document">
                            <div className="modal-content">

                                <div className="modal-header">
                                    <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                        <div className="close-container close-btn" aria-hidden="true">
                                            <div className="leftright"></div>
                                            <div className="rightleft"></div>
                                        </div>
                                    </button>
                                </div>

                                <div className="modal-body">

                                    <div className="project-image pinu">
                                        <div className="logo-wrapper">

                                            <img src="https://ik.imagekit.io/twl4vdftkm/FalPan/Icons/pinu-logo-white_u__idrm76.svg" alt="Pinu Logo" />

                                            <div className="visit">
                                                <div><a title="Back to Works" data-dismiss="modal" href="#home">Back to Projects</a></div>
                                                <div><a title="Visit external website" target="_blank" href="#home">Coming Soon!</a></div>
                                            </div>

                                            <div className="arrow-down-container">
                                                <i className="arrow-down-large" />
                                            </div>
                                        </div>
                                    </div>

                                    <h2>Interactive Breed based Food selector Widget</h2>
                                    <p>100% Adaptive/Responsive custom built component. Vector/SVG, SCSS, JS.</p>

                                    <div className="modal-image-wrapper"></div>

                                    <div className="modal-description">
                                        <h2>Project Details</h2>

                                        <div className="details">
                                            <div className="details-item">
                                                <h5>Project Status</h5>
                                                <p>Ongoing</p>
                                            </div>

                                            <div className="details-item">
                                                <h5>Estimated Completion</h5>
                                                <p>Dec 2021</p>
                                            </div>

                                            <div className="details-item">
                                                <h5>Team Total</h5>
                                                <p>3</p>
                                            </div>
                                        </div>

                                        <p>Pìnu is Online Pet Food Store. The project is ongoing, currently ongoing work on custom shopping cart design revamp with additional features + working on interactive food recommendation according to breed, body type, weight etc.</p>

                                        <p>This project is stated to be completed by the end of November 2020.</p>

                                        <button type="button" className="btn btn-default" data-dismiss="modal">Close</button>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>

                    {/* Edusity Article */}
                    <article className="timeline-entry animate-box" id="edusity" data-animate-effect="fadeInTop">

                        <aside className="next-wrapper timeline-entry animate-box" data-animate-effect="fadeInRight">
                            <a href="#thaliwalla" data-nav-section="thaliwalla">Next</a>
                        </aside>

                        <div className="project-desc">
                            <h1>Edusity</h1>
                            <div className="fancy-subtitle">Live Global Online Education &amp; Corporate training</div>
                            <span className="year">2021</span>
                        </div>

                        <div className="image-wrapper">
                            <div className="browser-bar">
                                <div className="dots-holder">
                                    <span></span>
                                    <span></span>
                                    <span></span>
                                </div>
                            </div>

                            <div className="image-container">
                                <img className="responsive" src={ screenshot0 } alt="Edusity website screen"/>

                                <div className="tablet-image">
                                    <img className="responsive" src={ screenshot0 } alt="Edusity tablet screen"/>
                                    <span className="close-view"></span>
                                </div>

                                <div className="mobile-image">
                                    <img className="responsive" src={ edusityMobile } alt="Edusity mobile screen"/>
                                    <span className="close-view"></span>
                                </div>
                            </div>
                        </div>

                        <div className="project-info">
                            <div><span>Role:</span> Lead re-engineering, re-branding, product compliance/accessibility Section 508/AODA/ADA/EU 301-549, WCAG 2.1 AA &amp; lead &amp; train core development team based in two continents.</div>
                            <div><span>Tech:</span> React JS, Node JS, Webpack, AWS, Sketch, SCSS/CSS3, JIRA, Confluence, Video customization &amp; integration.</div>
                        </div>

                        <div className="button-wrapper">
                            <button type="button" className="btn btn-primary" data-toggle="modal" data-target="#myModal">
                                Casestudy
                            </button>

                            <a className="btn globe" href="https://www.edusity.com/" target="_blank" rel="noopener noreferrer">Coming Soon</a>
                        </div>

                    </article>
                    
                    {/* Thaliwalla Article */}
                    <article className="timeline-entry animate-box" id="thaliwalla" data-animate-effect="fadeInTop">

                        <aside className="next-wrapper timeline-entry animate-box" data-animate-effect="fadeInRight">
                            <a href="#edusity" data-nav-section="edusity">Previous</a>
                            <a href="#webmyne" data-nav-section="webmyne">Next</a>
                        </aside>

                        <div className="project-desc">
                            <h1>Thaliwalla</h1>
                            <div className="fancy-subtitle">Authentic Indian Restaurant &amp; Delivery in London</div>
                            <span className="year">2020</span>
                        </div>

                        <div className="image-wrapper">
                            <div className="browser-bar">
                                <div className="dots-holder">
                                    <span></span>
                                    <span></span>
                                    <span></span>
                                </div>
                            </div>

                            <div className="image-container">
                                <img className="responsive" src={ screenshot } alt="Thaliwalla website screen"/>

                                <div className="tablet-image">
                                    <img className="responsive" src={ screenshot } alt="Thaliwalla tablet screen"/>
                                    <span className="close-view"></span>
                                </div>

                                <div className="mobile-image">
                                    <img className="responsive" src={ thaliwallaMobile } alt="Thaliwalla mobile screen"/>
                                    <span className="close-view"></span>
                                </div>
                            </div>
                        </div>

                        <div className="project-info">
                            <div><span>Role:</span> Strategy, Branding, Layout, Design Mockups. Development, Accessibility &amp; Deployment
                            </div>
                            <div><span>Tech:</span> Adobe Photoshop, Sketch, HTML5, SCSS/CSS3, jQuery, JS</div>
                        </div>

                        <div className="button-wrapper">
                            <button type="button" className="btn btn-primary" data-toggle="modal" data-target="#myModal1">
                                Casestudy
                            </button>
                        </div>

                    </article>

                    {/* Webmyne Article */}
                    <article className="timeline-entry animate-box" id="webmyne" data-animate-effect="fadeInTop">

                        <div className="next-wrapper timeline-entry animate-box" data-animate-effect="fadeInRight">
                            <a href="#thaliwalla" data-nav-section="hubub">Previous</a>
                            <a href="#hubub" data-nav-section="hubub">Next</a>
                        </div>

                        <div className="project-desc">
                            <h1>Webmyne Inc.</h1>
                            <div className="fancy-subtitle">SaaS Company with offices in Canada, the US, India &amp; the Philippines.</div>
                            <span className="year">2018</span>
                        </div>

                        <div className="image-wrapper">
                            <div className="browser-bar">
                                <div className="dots-holder">
                                    <span></span>
                                    <span></span>
                                    <span></span>
                                </div>
                            </div>

                            <div className="image-container">
                                <img className="responsive" src={ screenshot1 } alt="Webmyne website screen"/>

                                <div className="tablet-image">
                                    <img className="responsive" src={ screenshot1 } alt="Webmyne tablet screen"/>
                                    <span className="close-view"></span>
                                </div>

                                <div className="mobile-image">
                                    <img className="responsive" src={webmyneMobile} alt="Webmyne mobile screen"/>
                                    <span className="close-view"></span>
                                </div>
                            </div>
                        </div>

                        <div className="project-info">
                            <div><span>Role:</span> Custom Skin, QA, Development</div>
                            <div><span>Tech:</span> Adobe Photoshop, HTML5, CSS3, PHP, JS</div>
                        </div>

                        <div className="button-wrapper">
                            <button type="button" className="btn btn-primary btn-lg" data-toggle="modal" data-target="#myModal2">
                                Casestudy
                            </button>

                            <a className="btn globe" href="http://www.webmyne.com" rel="noopener noreferrer" target="_blank">Visit Webmyne</a>
                        </div>
                    </article>

                    {/* Hubub Article */}
                    <article className="timeline-entry animate-box" id="hubub" data-animate-effect="fadeInTop">

                        <div className="next-wrapper timeline-entry animate-box" data-animate-effect="fadeInRight">
                            <a href="#webmyne" data-nav-section="webmyne">Previous</a>
                            <a href="#gillette" data-nav-section="gillette">Next</a>
                        </div>

                        <div className="project-desc">
                            <h1 className="year">Hubub Inc.</h1>
                            <div className="fancy-subtitle">Social Media Platform/Startup with offices in Canada &amp; the US.</div>
                            <span>2018</span>
                        </div>

                        <div className="image-wrapper">
                            <div className="browser-bar">
                                <div className="dots-holder">
                                    <span></span>
                                    <span></span>
                                    <span></span>
                                </div>
                            </div>

                            <div className="image-container">
                                <img className="responsive" src={ screenshot2 } alt="Hubub website screen"/>

                                <div className="tablet-image">
                                    <img className="responsive" src={ screenshot2 } alt="Hubub tablet screen"/>
                                    <span className="close-view"></span>
                                </div>

                                <div className="mobile-image">
                                    <img className="responsive" src={ hububMobile } alt="Hubub mobile screen"/>
                                    <span className="close-view"></span>
                                </div>
                            </div>
                        </div>

                        <div className="project-info">
                            <div><span>Role:</span> Design Implementation, Brainstorming. UI/UX Development, Custom
                                compass mixin for Retina screens, Pioneered SVG integration, Code Reviews, Integration, Deployments
                            </div>
                            <div><span>Tech:</span> Sketch, Invision, HTML5, Compass/SCSS/CSS3, i18n, jQuery, JIRA, JS, PHP,
                                AWS, Docker, Git
                            </div>
                        </div>


                        <div className="button-wrapper">
                            <button type="button" className="btn btn-primary" data-toggle="modal" data-target="#myModal3">
                                Casestudy
                            </button>

                            <a className="btn globe" href="http://www.hubub.com" target="_blank" rel="noopener noreferrer">Visit
                                Hubub</a>
                        </div>
                    </article>

                    {/* Gillette Article */}
                    <article className="timeline-entry animate-box" id="gillette" data-animate-effect="fadeInTop">

                        <div className="next-wrapper timeline-entry animate-box" data-animate-effect="fadeInRight">
                            <a href="#hubub" data-nav-section="hubub">Previous</a>
                            <a href="#jpmorgan" data-nav-section="jpmorgan">Next</a>
                        </div>

                        <div className="project-desc">
                            <h1>Gillette Welcome Back</h1>
                            <div className="fancy-subtitle">Global brand of safety razors and other personal care products</div>
                            <span className="year">2017</span>
                        </div>

                        <div className="image-wrapper">
                            <div className="browser-bar">
                                <div className="dots-holder">
                                    <span></span>
                                    <span></span>
                                    <span></span>
                                </div>
                            </div>

                            <div className="image-container">
                                <img className="responsive" src={ screenshot3 } alt="Gillette website screen"/>

                                <div className="tablet-image">
                                    <img className="responsive" src={ screenshot3 } alt="Gillette tablet screen"/>
                                    <span className="close-view"></span>
                                </div>

                                <div className="mobile-image">
                                    <img className="responsive" src={ gilletteMobile } alt="Gillette mobile screen"/>
                                    <span className="close-view"></span>
                                </div>
                            </div>
                        </div>

                        <div className="project-info">
                            <div><span>Role:</span> Design Implementation, UI/UX Development, Custom Video Player, Integration, Deployments,
                            </div>
                            <div><span>Tech:</span> Sketch, Adobe Creative Suite, HTML5, Compass/SCSS/CSS3, JIRA, JS, PHP,
                                AWS, Git
                            </div>
                        </div>

                        <div className="button-wrapper">
                            <button type="button" className="btn btn-primary" data-toggle="modal" data-target="#myModal4">
                                Casestudy
                            </button>

                            <a className="btn globe" href="https://www.gillettewelcomeback.com/" target="_blank" rel="noopener noreferrer">Visit
                                Gillette Welcome Back</a>
                        </div>
                    </article>

                    {/* JP Morgan Article */}
                    <article className="timeline-entry animate-box hide" id="jpmorgan" data-animate-effect="fadeInTop">

                        <div className="next-wrapper timeline-entry animate-box" data-animate-effect="fadeInRight">
                            <a href="#gillette" data-nav-section="gillette">Previous</a>
                            <a href="#libre" data-nav-section="libre">Next</a>
                        </div>

                        <div className="project-desc">
                            <h1>JP Morgan</h1>
                            <div className="fancy-subtitle">Private Bank / Asset Management</div>
                            <span className="year">2018</span>
                        </div>

                        <div className="image-wrapper">
                            <div className="browser-bar">
                                <div className="dots-holder">
                                    <span></span>
                                    <span></span>
                                    <span></span>
                                </div>
                            </div>

                            <div className="image-container">
                                <img className="responsive" src={ screenshot4 } alt="JP Morgan website screen"/>

                                <div className="tablet-image">
                                    <img className="responsive" src={ screenshot4 } alt="JP Morgan tablet screen"/>
                                    <span className="close-view"></span>
                                </div>

                                <div className="mobile-image">
                                    <img className="responsive" src={ jpmorganMobile } alt="JP Morgan mobile screen"/>
                                    <span className="close-view"></span>
                                </div>
                            </div>
                        </div>

                        <div className="project-info">
                            <div><span>Role:</span> Design Implementation, UI/UX Development, Integration, Deployments
                            </div>
                            <div><span>Tech:</span> Sketch, Adobe Creative Suite, HTML5, Compass/SCSS/CSS3, ReactJS, JIRA, Git
                            </div>
                        </div>


                        <div className="button-wrapper">
                            <button type="button" className="btn btn-primary" data-toggle="modal" data-target="#myModal5">
                                Casestudy
                            </button>

                            <a className="btn globe" href="http://am.jpmorgan.com/ca/en/asset-management/institutional/" target="_blank" rel="noopener noreferrer">Visit
                                JP Morgan</a>
                        </div>
                    </article>

                    {/* Libre Article */}
                    <article className="timeline-entry animate-box" id="libre" data-animate-effect="fadeInTop">

                        <div className="next-wrapper timeline-entry animate-box" data-animate-effect="fadeInRight">
                            <a href="#jpmorgan" data-nav-section="jpmorgan">Previous</a>
                            <a href="#olay" data-nav-section="olay">Next</a>
                        </div>

                        <div className="project-desc">
                            <h1>L1bre App</h1>
                            <div className="fancy-subtitle">Ride sharing Application</div>
                            <span className="year">2017</span>
                        </div>

                        <div className="image-wrapper">
                            <div className="image-container">
                                <img className="responsive" src={screenshot5} alt="L1bre App"/>
                            </div>
                        </div>

                        <div className="project-info">
                            <div><span>Small Role:</span> Bug fixes, SVG icon integration, feature updates, integration</div>
                            <div><span>Tech:</span> Sketch, Invision, HTML5, SCSS/CSS3, AngularJS, Git</div>
                        </div>
                    </article>

                    {/* Olay Article */}
                    <article className="timeline-entry animate-box" id="olay" data-animate-effect="fadeInTop">

                        <div className="next-wrapper timeline-entry animate-box" data-animate-effect="fadeInRight">
                            <a href="#libre" data-nav-section="libre">Previous</a>
                            <a href="#hp" data-nav-section="hp">Next</a>
                        </div>

                        <div className="project-desc">
                            <h1>Olay [Email Blast]</h1>
                            <div className="fancy-subtitle">Global beauty & skincare brand</div>
                            <span className="year">2018</span>
                        </div>

                        <div className="image-wrapper">
                            <div className="image-container">
                                <img className="responsive" src={screenshot6} alt="Olay email blast"/>
                            </div>
                        </div>

                        <div className="project-info">
                            <div><span>Role:</span> Email template, client provided (images & discount codes), Integration,
                                Delivery
                            </div>
                            <div><span>Tech:</span> Photoshop, HTML5, CSS, JS, Google Analytics</div>
                        </div>

                        <div className="button-wrapper">
                            <button type="button" className="btn btn-primary btn-lg" data-toggle="modal" data-target="#myModal6">
                                Casestudy
                            </button>
                        </div>
                    </article>

                    {/* HP Article */}
                    <article className="timeline-entry animate-box" id="hp" data-animate-effect="fadeInTop">

                        <div className="next-wrapper timeline-entry animate-box" data-animate-effect="fadeInRight">
                            <a href="#olay" data-nav-section="olay">Previous</a>
                            <a href="#cr" data-nav-section="cr">Next</a>
                        </div>

                        <div className="project-desc">
                            <h1>Hewlett Packard</h1>
                            <div className="fancy-subtitle">Technology solutions for consumers & businesses</div>
                            <span className="year">2018</span>
                        </div>

                        <div className="image-wrapper">
                            <div className="browser-bar">
                                <div className="dots-holder">
                                    <span></span>
                                    <span></span>
                                    <span></span>
                                </div>
                            </div>

                            <div className="image-container">
                                <img className="responsive" src={ screenshot7 } alt="HP website screen"/>

                                <div className="tablet-image">
                                    <img className="responsive" src={ screenshot7 } alt="HP tablet screen"/>
                                    <span className="close-view"></span>
                                </div>

                                <div className="mobile-image">
                                    <img className="responsive" src={ hpMobile } alt="HP mobile screen"/>
                                    <span className="close-view"></span>
                                </div>
                            </div>
                        </div>

                        <div className="project-info">
                            <div><span>Small Role:</span> CSS Implementation, updates, API, bug fixes</div>
                            <div><span>Tech:</span> ReactJS, SCSS/CSS3</div>
                        </div>

                        <div className="button-wrapper">
                            <button type="button" className="btn btn-primary" data-toggle="modal" data-target="#myModal7">
                                Casestudy
                            </button>

                            <a className="btn globe" href="https://support.hp.com/us-en/drivers/printers" target="_blank" rel="noopener noreferrer">Visit
                                Hewlett Packard</a>
                        </div>
                    </article>

                    {/* Commodity Rentals Article */}
                    <article className="timeline-entry animate-box" id="cr" data-animate-effect="fadeInTop">

                        <div className="next-wrapper timeline-entry animate-box" data-animate-effect="fadeInRight">
                            <a href="#hp" data-nav-section="hp">Previous</a>
                            <a href="#alliance" data-nav-section="alliance">Next</a>
                        </div>

                        <div className="project-desc">
                            <h1>Commodity Rentals</h1>
                            <div className="fancy-subtitle">Out of the Box Technology & Business solutions</div>
                            <span className="year">2009</span>
                        </div>

                        <div className="image-wrapper">
                            <div className="browser-bar">
                                <div className="dots-holder">
                                    <span></span>
                                    <span></span>
                                    <span></span>
                                </div>
                            </div>

                            <div className="image-container">
                                <img className="responsive" src={ screenshot8 } alt="Commodity Rentals website screen"/>

                                <div className="tablet-image">
                                    <img className="responsive" src={ screenshot8 } alt="Commodity Rentals tablet screen"/>
                                    <span className="close-view"></span>
                                </div>

                                <div className="mobile-image">
                                    <img className="responsive" src={ crMobile } alt="Commodity Rentals mobile screen"/>
                                    <span className="close-view"></span>
                                </div>
                            </div>
                        </div>

                        <div className="project-info">
                            <div><span>Role:</span> Design, Development, delivery</div>
                            <div><span>Tech:</span> HTML5, SCSS/CSS3, JS, PHP</div>
                        </div>

                        <div className="button-wrapper">
                            <button type="button" className="btn btn-primary" data-toggle="modal" data-target="#myModal8">
                                Casestudy
                            </button>

                            <a className="btn globe" href="http://www.commodityrentals.com" target="_blank" rel="noopener noreferrer">Visit
                                Commodity Rentals</a>
                        </div>
                    </article>

                    {/* Alliance Article */}
                    <article className="timeline-entry animate-box" id="alliance" data-animate-effect="fadeInTop">

                        <div className="next-wrapper timeline-entry animate-box" data-animate-effect="fadeInRight">
                            <a href="#cr" data-nav-section="cr">Previous</a>
                            <a href="#med2x" data-nav-section="med2x">Next</a>
                        </div>

                        <div className="project-desc">
                            <h1>Alliance Chiropractor</h1>
                            <div className="fancy-subtitle">Chiropractor & Wellness Clinic</div>
                            <span className="year">2010</span>
                        </div>

                        <div className="image-wrapper">
                            <div className="browser-bar">
                                <div className="dots-holder">
                                    <span></span>
                                    <span></span>
                                    <span></span>
                                </div>
                            </div>

                            <div className="image-container">
                                <img className="responsive" src={ screenshot13 } alt="Alliance Chiropractors website"/>

                                <div className="tablet-image">
                                    <img className="responsive" src={ screenshot13 } alt="Alliance Chiropractor tablet screen"/>
                                    <span className="close-view"></span>
                                </div>

                                <div className="mobile-image">
                                    <img className="responsive" src={ acMobile } alt="Alliance Chiropractors"/>
                                    <span className="close-view"></span>
                                </div>
                            </div>
                        </div>

                        <div className="project-info">
                            <div><span>Role:</span> Design, Development, Delivery, Responsive/Mobile updated 2017</div>
                            <div><span>Tech:</span> Adobe Photoshop, xHTML, CSS, JS, PHP</div>
                        </div>

                        <div className="button-wrapper">
                            <button type="button" className="btn btn-primary" data-toggle="modal" data-target="#myModal9">
                                Casestudy
                            </button>

                            <a className="btn globe" href="http://www.alliancechiroandwellness.com" target="_blank" rel="noopener noreferrer">Visit
                                Alliance Chiropractors</a>
                        </div>
                    </article>

                    {/* Med2X Article */}
                    <article className="timeline-entry animate-box" id="med2x" data-animate-effect="fadeInTop">

                        <div className="next-wrapper timeline-entry animate-box" data-animate-effect="fadeInRight">
                            <a href="#alliance" data-nav-section="alliance">Previous</a>
                            <a href="#mst" data-nav-section="mst">Next</a>
                        </div>

                        <div className="project-desc">
                            <h1>Med2X</h1>
                            <div className="fancy-subtitle">Hospital Management System</div>
                            <span className="year">2008</span>
                        </div>

                        <div className="image-wrapper">
                            <div className="browser-bar">
                                <div className="dots-holder">
                                    <span></span>
                                    <span></span>
                                    <span></span>
                                </div>
                            </div>

                            <div className="image-container">
                                <img className="responsive" src={ screenshot9 } alt="Med2X Hospital Management System"/>

                                <div className="tablet-image">
                                    <img className="responsive" src={ screenshot9 } alt="Med2X Hospital Management System tablet screen"/>
                                    <span className="close-view"></span>
                                </div>

                                <div className="mobile-image">
                                    <img className="responsive" src={ med2xMobile } alt="Med2X Hospital Management System mobile screen"/>
                                    <span className="close-view"></span>
                                </div>
                            </div>
                        </div>

                        <div className="project-info">
                            <div><span>Role:</span> Design, Development, Delivery, Responsive/Mobile updated 2017</div>
                            <div><span>Tech:</span> Adobe Photoshop, Flash, XML, xHTML, CSS, JS, PHP, FTP</div>
                        </div>

                        <div className="button-wrapper">
                            <button type="button" className="btn btn-primary" data-toggle="modal" data-target="#myModal10">
                                Casestudy
                            </button>

                            <a className="btn globe" href="http://www.med2x.com" target="_blank" rel="noopener noreferrer">Visit
                                Med2X</a>
                        </div>
                    </article>

                    {/* MST Article */}
                    <article className="timeline-entry animate-box" id="mst" data-animate-effect="fadeInTop">

                        <div className="next-wrapper timeline-entry animate-box" data-animate-effect="fadeInRight">
                            <a href="#med2x" data-nav-section="med2x">Previous</a>
                            <a href="#guelph" data-nav-section="guelph">Next</a>
                        </div>

                        <div className="project-desc">
                            <h1>Magic Survey Tool</h1>
                            <div className="fancy-subtitle">Online Survey & Poll Management Tool</div>
                            <span className="year">2009</span>
                        </div>

                        <div className="image-wrapper">
                            <div className="browser-bar">
                                <div className="dots-holder">
                                    <span></span>
                                    <span></span>
                                    <span></span>
                                </div>
                            </div>

                            <div className="image-container">
                                <img className="responsive" src={screenshot10} alt="Magic Survey Tool"/>
                            </div>
                        </div>

                        <div className="project-info">
                            <div><span>Role:</span> Mockups, Design, User Flows, Front End Development, Delivery</div>
                            <div><span>Tech:</span> Adobe Photoshop, xHTML, CSS, JS, PHP</div>
                        </div>

                        <div className="button-wrapper">
                            <button type="button" className="btn btn-primary" data-toggle="modal" data-target="#myModal11">
                                Casestudy
                            </button>

                            <a className="btn globe" href="http://www.magicsurveytool.com" target="_blank" rel="noopener noreferrer">Visit
                                Magic Survey Tool</a>
                        </div>
                    </article>

                    {/* GCR Article */}
                    <article className="timeline-entry animate-box" id="guelph" data-animate-effect="fadeInTop">

                        <div className="next-wrapper timeline-entry animate-box" data-animate-effect="fadeInRight">
                            <a href="#mst" data-nav-section="mst">Previous</a>
                            <a href="#pinu" data-nav-section="thaliwalla">Next</a>
                        </div>

                        <div className="project-desc">
                            <h1>Guelph City Realty</h1>
                            <div className="fancy-subtitle">Realty Listing Website</div>
                            <span className="year">2020</span>
                        </div>

                        <div className="image-wrapper">
                            <div className="browser-bar">
                                <div className="dots-holder">
                                    <span></span>
                                    <span></span>
                                    <span></span>
                                </div>
                            </div>

                            <div className="image-container">
                                <img className="responsive" src={screenshot14} alt="Guelph City Realty"/>

                                <div className="tablet-image">
                                    <img className="responsive" src={ screenshot14 }
                                         alt="Guelph City Realty tablet screen"/>
                                    <span className="close-view"></span>
                                </div>

                                <div className="mobile-image">
                                    <img className="responsive" src={ guelphMobile }
                                         alt="Guelph City Realty mobile screen"/>
                                    <span className="close-view"></span>
                                </div>
                            </div>
                        </div>

                        <div className="project-info">
                            <div><span>Role:</span> Concept, Artwork Design, Vector Animation, Development</div>
                            <div><span>Tech:</span> Adobe Illustrator, Sketch, Angular 8, NPM, NodeJS, API Integration</div>
                        </div>

                        <div className="button-wrapper">
                            <button type="button" className="btn btn-primary" data-toggle="modal" data-target="#myModal12">
                                Casestudy
                            </button>

                            <a className="btn globe" href="#home" target="_blank" rel="noopener noreferrer">Coming Soon!</a>
                        </div>
                    </article>

                    {/* Pinu Article */}
                    <article className="timeline-entry animate-box" id="pinu" data-animate-effect="fadeInTop">

                        <div className="next-wrapper timeline-entry animate-box" data-animate-effect="fadeInRight">
                            <a href="#guelph" data-nav-section="mst">Previous</a>
                            <a href="#thaliwalla" data-nav-section="thaliwalla">Works</a>
                        </div>

                        <div className="project-desc">
                            <h1>Pìnu</h1>
                            <div className="fancy-subtitle">Online Pet Food Store</div>
                            <span className="year">2020</span>
                        </div>

                        <div className="image-wrapper">
                            <div className="browser-bar">
                                <div className="dots-holder">
                                    <span></span>
                                    <span></span>
                                    <span></span>
                                </div>
                            </div>

                            <div className="image-container">
                                <img className="responsive" src={ screenshot15 } alt="Pinu"/>

                                <div className="tablet-image">
                                    <img className="responsive" src={ screenshot15 }
                                         alt="Pinu tablet screen"/>
                                    <span className="close-view"></span>
                                </div>

                                <div className="mobile-image">
                                    <img className="responsive" src={ pinuMobile }
                                         alt="Guelph City Realty mobile screen"/>
                                    <span className="close-view"></span>
                                </div>
                            </div>
                        </div>

                        <div className="project-info">
                            <div><span>Role:</span> Concept, Design, Development</div>
                            <div><span>Tech:</span> Adobe Photoshop, Sketch, Angular 8, NPM, NodeJS, Shopping Cart, API Integration</div>
                        </div>

                        <div className="button-wrapper">
                            <button type="button" className="btn btn-primary" data-toggle="modal" data-target="#myModal13">
                                Casestudy
                            </button>

                            <a className="btn globe" href="#home">Coming Soon!</a>
                        </div>
                    </article>

                </div>
            </section>

        )
    }
}
